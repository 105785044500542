@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Libre+Baskerville&display=swap');

:root {
  --primary-color: #04385C;
  --secondary-color: #FBF7E5;
  --tertiary-color: #8B133B;
  --text-color: #20252D;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

body {
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  font-family: 'Lato', sans-serif;
}

h1 {
  font-size: 72px;
}


*::before,
*::after {
  transition: none !important;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 42px;
  }
}